import { createContext, useReducer } from 'react';

export const AdminContext = createContext();

const AdminReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ADMIN':
      return {
        ...state,
        admin: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        admin: null,
      };
    default:
      return state;
  }
};

export const AdminContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AdminReducer, {
    admin: null,
  });

  const logout = () => {
    localStorage.removeItem('adminToken');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AdminContext.Provider value={{ ...state, dispatch, logout }}>
      {children}
    </AdminContext.Provider>
  );
};
