import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import 'leaflet/dist/leaflet.css';
import io from 'socket.io-client';
import useAdmin from '../hooks/useAdmin';
import { useNavigate, Link } from 'react-router-dom';
import { MdDashboard, MdManageAccounts } from 'react-icons/md';
import SideMenu from '../components/SideMenu';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiamFoYm94IiwiYSI6ImNtM2VmbnQ3YjA5djcya3F0aHpnN2tvdnQifQ.dyEFQRnY2HnwQyj2Q2SgLw';
const OPENWEATHER_API_KEY = '75e3d25529822398ca1d7d219c5c44b8';
const sessionToken = uuidv4();

const crops = [
  {
    name: "Rice",
    optimalTemp: "20-30°C (68-86°F)",
    farming: "Flooded fields, requiring lots of water.",
    soilClimate: "Prefers loamy soils with good drainage.",
    harvestTime: "3-6 months after planting."
  },
  {
    name: "Cassava",
    optimalTemp: "24-30°C (75-86°F)",
    farming: "Grown in mounds or ridges, tolerant to drought.",
    soilClimate: "Thrives in well-drained sandy loams.",
    harvestTime: "8-24 months depending on the variety."
  },
  {
    name: "Cocoa",
    optimalTemp: "20-32°C (68-90°F)",
    farming: "Requires shade; often intercropped with other plants.",
    soilClimate: "Best in well-drained, fertile soils.",
    harvestTime: "5-6 years for first harvest, then yearly."
  },
  {
    name: "Rubber",
    optimalTemp: "25-30°C (77-86°F)",
    farming: "Tapped for latex; requires careful management.",
    soilClimate: "Prefers deep, well-drained soils.",
    harvestTime: "6-7 years for first tapping."
  },
  {
    name: "Oil Palm",
    optimalTemp: "24-28°C (75-82°F)",
    farming: "Planted in rows; requires significant water.",
    soilClimate: "Grows best in well-drained, loamy soils.",
    harvestTime: "3-4 years for first harvest."
  },
  {
    name: "Coffee",
    optimalTemp: "15-24°C (59-75°F)",
    farming: "Requires shade; often grown on hillsides.",
    soilClimate: "Prefers rich, well-drained soils.",
    harvestTime: "3-4 years for first harvest."
  },
  {
    name: "Maize (Corn)",
    optimalTemp: "20-30°C (68-86°F)",
    farming: "Direct-seeded; requires moderate water.",
    soilClimate: "Best in well-drained loamy soils.",
    harvestTime: "2-3 months after planting."
  },
  {
    name: "Sweet Potatoes",
    optimalTemp: "24-29°C (75-84°F)",
    farming: "Planted as slips; requires good drainage.",
    soilClimate: "Thrives in sandy loam soils.",
    harvestTime: "3-4 months after planting."
  },
  {
    name: "Groundnuts (Peanuts)",
    optimalTemp: "20-30°C (68-86°F)",
    farming: "Grows underground; requires well-drained soils.",
    soilClimate: "Prefers sandy loam soils with good drainage.",
    harvestTime: "3-4 months after planting."
  },
  {
    name: "Vegetables",
    optimalTemp: "15-25°C (59-77°F)",
    farming: "Varies by type; typically grown in rows.",
    soilClimate: "Best in well-drained, nutrient-rich soils.",
    harvestTime: "Varies widely depending on the type."
  },
];

const coordinates = [
  { id: "1", title: "Duoplay Town", latitude: 7.28411, longitude: -8.42206 },
  { id: "2", title: "Kenlay #1", latitude: 7.29208, longitude: -8.37751 },
  { id: "3", title: "Zeanlay #2", latitude: 7.28688, longitude: -8.39989 },
  { id: "4", title: "Nyoaplay", latitude: 7.30326, longitude: -8.45434 },
  { id: "5", title: "Gbaunplay", latitude: 7.316807, longitude: -8.426703 },
  { id: "6", title: "Kpantiaplay", latitude: 7.25763, longitude: -8.36636 },
  { id: "7", title: "Sarkolay", latitude: 7.32152, longitude: -8.47204 },
  { id: "8", title: "Younlay", latitude: 7.34096, longitude: -8.44485 },
];

const LocationMarkerWithPopup = ({ position, title }) => {
  const markerRef = React.useRef(null);

  React.useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [position]);

  return (
    <Marker position={position} ref={markerRef}>
      <Popup>{title}</Popup>
    </Marker>
  );
};

const LandingScreen = () => {
  const { admin, logout } = useAdmin();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // This will clear admin state and remove token from localStorage
    navigate('/'); // Redirect to login page
  };

  const [location, setLocation] = useState(null);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [savedSuggestions, setSavedSuggestions] = useState([]);
  const [weatherData, setWeatherData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [position, setPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      setLocation({ latitude, longitude });
      setMapCenter({ latitude, longitude });
      fetchWeatherData(latitude, longitude);
    });
  }, []);

  useEffect(() => {
    const socket = io('https://weatherappbackend-0wvp.onrender.com');
  
    socket.on('update-user-status', (data) => {
      setAllUsers(prevUsers => {
        return prevUsers.map(user => 
          user._id === data._id 
            ? { 
                ...user, 
                activeStatus: data.activeStatus,
                latitude: data.latitude,
                longitude: data.longitude
              }
            : user
        );
      });
    });
  
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await fetch('https://weatherappbackend-0wvp.onrender.com/api/user/all', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await response.json();
      setAllUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setAllUsers([]);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    console.log(user);
    if (user.latitude && user.longitude) {
      setLocation({
        latitude: user.latitude,
        longitude: user.longitude,
      });
      setMapCenter({
        latitude: user.latitude,
        longitude: user.longitude,
      });
      fetchWeatherData(user.latitude, user.longitude);
    }
  };

  const fetchSuggestions = async (text) => {
    if (!text) return;
    setQuery(text);
    if (text?.length > 2) {
      try {
        const response = await fetch(
          `https://api.mapbox.com/search/searchbox/v1/suggest?q=${text}&access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${sessionToken}&proximity=${location.longitude},${location.latitude}&limit=5`
        );
        const data = await response.json();
        setSuggestions(data.suggestions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const fetchLocationDetails = async (mapbox_id, placeFormatted) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/search/searchbox/v1/retrieve/${mapbox_id}?access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${sessionToken}`
      );
      const data = await response.json();
      const coordinates = data.features[0].geometry.coordinates;
      const latitude = coordinates[1];
      const longitude = coordinates[0];

      setLocation({
        latitude,
        longitude,
      });
      setMapCenter({ latitude, longitude });
      setQuery(placeFormatted);
      setSuggestions([]);

      const newSuggestion = {
        mapbox_id,
        placeFormatted,
        latitude,
        longitude,
      };

      if (!savedSuggestions.some((suggestion) => suggestion.mapbox_id === mapbox_id)) {
        setSavedSuggestions([...savedSuggestions, newSuggestion]);
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const fetchWeatherData = async (lat, lon) => {
    try {
      const currentResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${OPENWEATHER_API_KEY}`
      );

      const data = currentResponse.data;
      const tempCelsius = data.main.temp;
      const tempFahrenheit = Math.round((tempCelsius * 9) / 5 + 32);

      const forecastResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${OPENWEATHER_API_KEY}`
      );

      setWeatherData({
        ...data,
        tempCelsius,
        tempFahrenheit,
        historical: forecastResponse.data.list,
      });
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  const handleSavedLocationPress = (savedLocation) => {
    setLocation({
      latitude: savedLocation.latitude,
      longitude: savedLocation.longitude,
    });
    setMapCenter({
      latitude: savedLocation.latitude,
      longitude: savedLocation.longitude,
    });
    fetchWeatherData(savedLocation.latitude, savedLocation.longitude);
  };

  const handleDeleteLocation = (index) => {
    setSavedSuggestions((prevSuggestions) =>
      prevSuggestions.filter((_, i) => i !== index)
    );
  };

  const getWeatherIcon = (description) => {
    switch (description) {
      case 'clear sky':
        return '☀️';
      case 'light rain':
        return '🌧️';
      case 'broken clouds':
        return '☁️';
      case 'few clouds':
        return '🌤️';
      case 'rain':
        return '🌧️';
      default:
        return '☁️';
    }
  };

  const LocationMarker = () => {
    const map = useMap();
    useEffect(() => {
      if (mapCenter) {
        map.flyTo([mapCenter.latitude, mapCenter.longitude], map.getZoom());
      }
    }, [mapCenter, map]);
  
    return location ? (
      <Marker position={[location.latitude, location.longitude]}>
        <Popup>Current Location</Popup>
      </Marker>
    ) : null;
  };

  const MapWithClickHandler = ({ onMapClick }) => {
    useMapEvents({
      click: (event) => {
        const { lat, lng } = event.latlng;
        if (lat && lng) {
          onMapClick({ latitude: lat, longitude: lng });
        } else {
          console.error('Invalid lat/lng from map click:', event.latlng);
        }
      },
    });
  
    return null;
  };

  const handleMarkerPress = (marker) => {
    setSelectedLocation(marker); 
    fetchWeatherData(marker.coordinate.latitude, marker.coordinate.longitude);
    setMapCenter({
      latitude: marker.coordinate.latitude,
      longitude: marker.coordinate.longitude,
    });
  };

  const handleTextChange = (text) => {
    setQuery(text);
    if (text.length === 0) {
      setSuggestions([]); // Set suggestions to an empty array when input is empty
    } else {
      fetchSuggestions(text); // Fetch suggestions otherwise
    }
  };

  const getUniqueForecastDates = (data) => {
    const uniqueDates = {};
    data.forEach(item => {
      const date = item.dt_txt.split(" ")[0];
      if (!uniqueDates[date]) {
        uniqueDates[date] = item; // Store the first occurrence of each date
      }
    });
  
    // Return the unique dates as an array
    return Object.values(uniqueDates);
  };

  const handleLocationPress = (coordinate) => {
    setMapCenter({
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
    });
    setLocation({
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
    });
    setSelectedLocation({
      coordinate: {
        latitude: coordinate.latitude,
        longitude: coordinate.longitude
      },
      title: coordinate.title
    });
    fetchWeatherData(coordinate.latitude, coordinate.longitude);
  };

  return (
    <div style={styles.pageContainer}>
      <SideMenu />
      <div style={styles.mainContent}>
        <div style={styles.container}>
          <header style={styles.header}>
            <input
              type="text"
              placeholder="Search for a location"
              value={query}
              onChange={(e) => handleTextChange(e.target.value)}
              style={styles.searchInput}
            />
          </header>
          {/* <div style={styles.header}>
            <div style={styles.headerContent}>
              <h2 style={styles.welcomeText}>Hello, {admin?.username}</h2>
              <button 
                onClick={handleLogout}
                style={styles.logoutButton}
              >
                Logout
              </button>
            </div>
          </div> */}

          {/* Suggestions */}
          {Array.isArray(suggestions) && suggestions.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              {suggestions.map((item) => (
                <button
                  key={item.mapbox_id}
                  onClick={() => fetchLocationDetails(item.mapbox_id, item.name)}
                  style={{
                    display: "block",
                    padding: "10px",
                    marginBottom: "5px",
                    backgroundColor: "#f0f0f0",
                    border: "1px solid #ddd",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}

          {/* Saved Locations */}
          <div>
            <h2 style={styles.sectionTitle}>Saved Locations</h2>
            {savedSuggestions.map((savedLocation, index) => (
              <div key={index} style={styles.savedLocation}>
                <button 
                style={styles.savedSuggestionButton}
                onClick={() => handleSavedLocationPress(savedLocation)}
                >
                  {savedLocation.placeFormatted}
                </button>
                <button
                  onClick={() => handleDeleteLocation(index)}
                  style={styles.deleteButton}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>

          {/* Scrollable List of Locations */}
          <div style={styles.locationScroll}>
            {coordinates.map((location) => (
              <button
                key={location.id}
                style={styles.locationItem}
                onClick={() => handleLocationPress(location)}
              >
                <span style={styles.locationText}>{location.title}</span>
              </button>
            ))}
          </div>

          {/* Users List */}
          <div style={styles.activeUsersContainer}>
            <h3 style={styles.sectionTitle}>Users</h3>
            <div style={styles.userList}>
              {allUsers.map((user) => (
                <div
                  key={user._id}
                  style={{
                    ...styles.userItem,
                    backgroundColor: user.activeStatus ? '#e8f5e9' : '#f5f5f5',
                    cursor: user.activeStatus ? 'pointer' : 'default'
                  }}
                  onClick={() => user.activeStatus && handleUserSelect(user)}
                >
                  <div style={styles.userStatus}>
                    <span style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: user.activeStatus ? '#4caf50' : '#9e9e9e',
                      display: 'inline-block',
                      marginRight: '8px'
                    }} />
                    {user.username}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Map Section */}
          {location && (
            <MapContainer 
            center={[location.latitude, location.longitude]} 
            zoom={13} 
            style={styles.map}
            scrollWheelZoom={true}
            doubleClickZoom={true}
            dragging={true}
            zoomControl={true}
          >
            <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <LocationMarker />
            <Marker position={[location.latitude, location.longitude]}>
              <Popup>Current Location</Popup>
            </Marker>
            {selectedLocation && selectedLocation.coordinate.latitude && selectedLocation.coordinate.longitude && (
              <LocationMarkerWithPopup 
                position={[selectedLocation.coordinate.latitude, selectedLocation.coordinate.longitude]}
                title={selectedLocation.title}
              />
            )}
            {activeUsers.map((user) => (
              user.activeStatus && user.location && (
                <LocationMarkerWithPopup
                  key={user._id}
                  position={[user.location.latitude, user.location.longitude]}
                  title={`User: ${user.username}`}
                />
              )
            ))}
            <MapWithClickHandler
              onMapClick={(coordinates) => {
                handleMarkerPress({
                  coordinate: coordinates,
                  title: 'Selected Location',
                });
              }}
            />
          </MapContainer>
          )}

          {/* Main Content */}
          <div style={styles.weatherSection}>
            {/* Current Weather */}
            {weatherData && (
              <div style={styles.currentWeatherCard}>
                <h2>Current Weather</h2>
                <p>{weatherData.tempFahrenheit}°F</p>
                <p>{weatherData.weather[0].description}</p>
                {getWeatherIcon(weatherData.weather[0].description)}
              </div>
            )}

            {/* 5-Day Forecast */}
            {weatherData && weatherData.historical && (
              <div style={styles.forecastContainer}>
                {getUniqueForecastDates(weatherData.historical).map((item, index) => (
                  <div key={index} style={styles.forecastCard}>
                    <h3>{format(new Date(item.dt_txt), 'MMM dd')}</h3>
                    {getWeatherIcon(item.weather[0].description)}
                    <p>{Math.round((item.main.temp - 273.15) * 9 / 5 + 32)}°F</p>
                    <p>{item.weather[0].description}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Crop Information */}
          <div style={styles.cropContainer}>
            <h2 style={styles.sectionTitle}>Crop Information</h2>
            <div style={styles.cropList}>
              {crops.map((crop, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedCrop(crop)}
                  style={styles.cropItem}
                >
                  <span style={styles.cropName}>{crop.name}</span>
                </button>
              ))}
            </div>
            {selectedCrop && (
              <div style={styles.cropDetails}>
                <p>Impact on {selectedCrop.name}:</p>
                <p>Optimal Temperature: {selectedCrop.optimalTemp}</p>
                <p>Farming Method: {selectedCrop.farming}</p>
                <p>Preferred Soil and Climate: {selectedCrop.soilClimate}</p>
                <p>Harvest Time: {selectedCrop.harvestTime}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    minHeight: '100vh',
  },
  mainContent: {
    flex: 1,
    padding: '20px',
    marginLeft: '180px',
  },
  container: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "20px",
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  welcomeText: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  searchInput: {
    flex: 1,
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  savedLocation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    marginBottom: "5px",
    backgroundColor: "#f0f0f0",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  savedSuggestionButton: {
    display: "block",
    padding: "10px",
    marginBottom: "5px",
    backgroundColor: "#e3f2fd",
    color: "#01579b",
    border: "1px solid #0288d1",
    borderRadius: "5px",
    textAlign: "left",
    cursor: "pointer",
    transition: "all 0.3s ease",
    ':hover': {
      backgroundColor: "#0288d1"
    }
  },
  savedSuggestionButtonHover: {
    backgroundColor: "#0288d1",
    color: "#fff",
  },
  deleteButton: {
    padding: "5px 10px",
    backgroundColor: "#ff5c5c",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  weatherSection: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    marginTop: "20px",
  },
  currentWeatherCard: {
    flex: 1,
    textAlign: "center",
    backgroundColor: "#e0e7ff", 
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  forecastContainer: {
    flex: 3,
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
  },
  forecastCard: {
    flex: 1,
    textAlign: "center",
    backgroundColor: "#edf2ff", 
    padding: "15px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  map: {
    height: "300px",
    width: "100%",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginTop: "20px",
  },
  cropContainer: {
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginTop: "20px",
  },
  main: {
    display: "grid",
    gridTemplateColumns: "1fr", 
    gap: "20px",
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#2c3e50',
    marginBottom: '15px',
    paddingBottom: '8px',
    borderBottom: '2px solid #ecf0f1',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },
  cropTitle: {
    fontSize: "18px",
    marginBottom: "10px",
  },
  cropList: {
    display: "flex",
    gap: "10px",
  },
  cropItem: {
    padding: "10px",
    backgroundColor: "#f0f0f0",
    border: "1px solid #ddd",
    borderRadius: "5px",
    cursor: "pointer",
  },
  cropDetails: {
    marginTop: "10px",
  },
  locationScroll: {
    display: 'flex',
    overflowX: 'auto',
    backgroundColor: "#f8f8f8",
    padding: "10px 5px",
    marginBottom: "20px",
    WebkitOverflowScrolling: 'touch',
  },
  locationItem: {
    padding: "10px",
    margin: "0 5px",
    borderRadius: "5px",
    backgroundColor: "#2c3e50",
    border: "none",
    cursor: "pointer",
    whiteSpace: "nowrap",
    display: "inline-block",
    minWidth: "fit-content",
    color: "#fff",
    transition: "background-color 0.3s ease",
    ':hover': {
      backgroundColor: "#34495e"
    }
  },
  locationText: {
    fontSize: "14px",
    color: "#ecf0f1",
  },
  activeUsersContainer: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  userList: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  userItem: {
    padding: '10px',
    margin: '5px 0',
    borderRadius: '4px',
    transition: 'background-color 0.2s',
  },
  userStatus: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
};

export default LandingScreen;
