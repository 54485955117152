import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdDashboard, MdManageAccounts, MdSettings } from 'react-icons/md';
import useAdmin from '../hooks/useAdmin';
import wfpLogo from '../assets/wfplogo.png';

const SideMenu = () => {
  const { admin, logout } = useAdmin();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div style={styles.sideMenu}>
      <div style={styles.menuHeader}>
        <img src={wfpLogo} alt="WFP Logo" style={styles.logo} />
      </div>
      <nav style={styles.menuNav}>
        <Link 
          to="/dashboard" 
          style={styles.menuLink}
          onMouseEnter={e => {
            e.target.style.backgroundColor = '#34495e';
            e.target.style.color = '#fff';
            e.target.style.paddingLeft = '10px';
          }}
          onMouseLeave={e => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = '#ecf0f1';
            e.target.style.paddingLeft = '5px';
          }}
        >
          <div style={styles.menuLinkContent}>
            <MdDashboard style={styles.menuIcon} />
            <span>Dashboard</span>
          </div>
        </Link>
        <Link 
          to="/account-management" 
          style={styles.menuLink}
          onMouseEnter={e => {
            e.target.style.backgroundColor = '#34495e';
            e.target.style.color = '#fff';
            e.target.style.paddingLeft = '10px';
          }}
          onMouseLeave={e => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = '#ecf0f1';
            e.target.style.paddingLeft = '5px';
          }}
        >
          <div style={styles.menuLinkContent}>
            <MdManageAccounts style={styles.menuIcon} />
            <span>Account Management</span>
          </div>
        </Link>
        <Link 
          to="/settings" 
          style={styles.menuLink}
          onMouseEnter={e => {
            e.target.style.backgroundColor = '#34495e';
            e.target.style.color = '#fff';
            e.target.style.paddingLeft = '10px';
          }}
          onMouseLeave={e => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = '#ecf0f1';
            e.target.style.paddingLeft = '5px';
          }}
        >
          <div style={styles.menuLinkContent}>
            <MdSettings style={styles.menuIcon} />
            <span>Settings</span>
          </div>
        </Link>
      </nav>
      <div style={styles.userSection}>
        <p style={styles.welcomeText}>Hello, {admin?.username}</p>
        <button 
          onClick={handleLogout}
          style={styles.logoutButton}
          onMouseEnter={e => {
            e.target.style.backgroundColor = '#fff';
            e.target.style.color = '#2c3e50';
          }}
          onMouseLeave={e => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = '#fff';
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

const styles = {
  sideMenu: {
    width: '180px',
    backgroundColor: '#2c3e50',
    borderRadius: '0 20px 20px 0',
    padding: '20px 15px 20px 5px',
    color: '#fff',
    boxShadow: '4px 0 15px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  menuHeader: {
    marginBottom: '30px',
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '15px',
  },
  logo: {
    width: '40px',
    height: 'auto',
    objectFit: 'contain',
    display: 'block',
  },
  menuTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0',
    color: '#ecf0f1',
    textAlign: 'left',
    paddingLeft: '5px',
  },
  menuNav: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    paddingLeft: '0',
    flex: '1 1 auto',
    marginBottom: '20px',
  },
  menuLink: {
    color: '#ecf0f1',
    textDecoration: 'none',
    padding: '12px 10px 12px 5px',
    borderRadius: '10px',
    fontSize: '15px',
    fontFamily: "'Arial', sans-serif",
    fontWeight: '500',
    letterSpacing: '0.3px',
    transition: 'all 0.3s ease',
    display: 'block',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    width: 'calc(100% - 5px)',
    textAlign: 'left',
  },
  menuLinkContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingLeft: '5px',
  },
  menuIcon: {
    fontSize: '20px',
  },
  userSection: {
    marginTop: 'auto',
    marginBottom: '20px',
    padding: '0 5px',
  },
  welcomeText: {
    fontSize: '14px',
    margin: '0 0 10px 5px',
    color: '#ecf0f1',
  },
  logoutButton: {
    padding: '8px 15px',
    backgroundColor: 'transparent',
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    width: '100%',
    marginBottom: '20px',
  },
};

export default SideMenu;
