import React, { useState } from 'react';
import useAdmin from '../hooks/useAdmin';
import SideMenu from '../components/SideMenu';

const Settings = () => {
  const { admin } = useAdmin();
  const [username, setUsername] = useState(admin?.username || '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const showNotification = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      showNotification("Passwords don't match!", 'error');
      return;
    }

    if (newPassword.length < 5) {
      showNotification('Password must be at least 5 characters long!', 'error');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('https://weatherappbackend-0wvp.onrender.com/api/admin/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          newPassword
        })
      });

      const data = await response.json();

      if (response.ok) {
        showNotification('Password updated successfully!', 'success');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        showNotification(data.error || 'Failed to update password', 'error');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      showNotification('Failed to update password', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.pageContainer}>
      <SideMenu />
      <div style={styles.mainContent}>
        <div style={styles.contentContainer}>
          <div style={styles.formSection}>
            <h1 style={styles.title}>Settings</h1>
            <div style={styles.formContainer}>
              <h2 style={styles.subtitle}>Update Admin Password</h2>
              {showAlert && (
                <div style={{
                  ...styles.alert,
                  backgroundColor: alertType === 'success' ? '#d4edda' : '#f8d7da',
                  color: alertType === 'success' ? '#155724' : '#721c24'
                }}>
                  {alertMessage}
                </div>
              )}
              <form onSubmit={handleUpdatePassword} style={styles.form}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  style={styles.input}
                  required
                  disabled
                />
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={styles.input}
                  required
                />
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={styles.input}
                  required
                />
                <button type="submit" style={styles.button} disabled={loading}>
                  {loading ? 'Updating...' : 'Update Password'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    height: '100vh',
    backgroundColor: '#f5f6fa',
  },
  mainContent: {
    flex: 1,
    padding: '2rem',
    overflowY: 'auto',
  },
  contentContainer: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  formSection: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '2rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  title: {
    color: '#2c3e50',
    marginBottom: '2rem',
    fontSize: '2rem',
  },
  subtitle: {
    color: '#34495e',
    marginBottom: '1rem',
    fontSize: '1.5rem',
  },
  formContainer: {
    maxWidth: '400px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  input: {
    padding: '0.75rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '1rem',
  },
  button: {
    padding: '0.75rem',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
    '&:disabled': {
      backgroundColor: '#ccc',
      cursor: 'not-allowed',
    },
  },
  alert: {
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: '4px',
    textAlign: 'center',
  },
};

export default Settings;
