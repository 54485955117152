import React, { useState, useEffect } from 'react';
import useAdmin from '../hooks/useAdmin';
import SideMenu from '../components/SideMenu';

const CustomAlert = ({ type, message, onClose }) => (
  <div 
    style={{
      ...styles.alert,
      backgroundColor: type === 'error' ? '#ffebee' : '#e8f5e9',
      color: type === 'error' ? '#c62828' : '#2e7d32',
      border: `1px solid ${type === 'error' ? '#ef9a9a' : '#a5d6a7'}`
    }}
  >
    {message}
    <button 
      onClick={onClose}
      style={styles.closeButton}
    >
      ×
    </button>
  </div>
);

const UserCard = ({ user, onDelete }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete user "${user.username}"?`)) {
      try {
        // Production URL
        const response = await fetch(`https://weatherappbackend-0wvp.onrender.com/api/user/delete/${user._id}`, {
        // Local development URL
        // const response = await fetch(`http://localhost:4000/api/user/delete/${user._id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (response.ok) {
          onDelete(user._id);
        } else {
          const data = await response.json();
          throw new Error(data.error || 'Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        alert(error.message);
      }
    }
  };

  return (
    <div style={styles.userCard}>
      <div style={styles.userInfo}>
        <div style={styles.userHeader}>
          <span style={styles.username}>{user.username}</span>
          <button
            onClick={handleDelete}
            style={styles.deleteButton}
          >
            Delete
          </button>
        </div>
        <div style={styles.passwordContainer}>
          <span style={styles.password}>
            {showPassword ? user.password : '•'.repeat(8)}
          </span>
          <button
            onClick={() => setShowPassword(!showPassword)}
            style={styles.showPasswordButton}
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
    </div>
  );
};

const AccountManagement = () => {
  const { admin } = useAdmin();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [users, setUsers] = useState([]);  
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // Production URL
      const response = await fetch('https://weatherappbackend-0wvp.onrender.com/api/user/all', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({}) // Empty object since we don't need to send any data
      });
      // Local development URL
      // const response = await fetch('http://localhost:4000/api/user/all', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({})
      // });
      
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      
      const data = await response.json();
      console.log('API Response:', data);
      
      // Ensure data is an array before setting it
      if (Array.isArray(data)) {
        setUsers(data);
      } else {
        console.error('Received non-array data:', data);
        setUsers([]); // Set empty array if data is invalid
        showNotification('Error loading users', 'error');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]); // Set empty array on error
      showNotification('Error loading users: ' + error.message, 'error');
    }
  };

  const showNotification = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      showNotification("Passwords don't match!", 'error');
      return;
    }

    if (password.length < 5) {
      showNotification('Password must be at least 5 characters long!', 'error');
      return;
    }

    setLoading(true);

    try {
      // Production URL
      const response = await fetch('https://weatherappbackend-0wvp.onrender.com/api/user/signup', {
      // Local development URL
      // const response = await fetch('http://localhost:4000/api/user/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password
        })
      });

      const data = await response.json();

      if (response.ok) {
        showNotification('User created successfully!', 'success');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        fetchUsers(); 
      } else {
        showNotification(data.error || 'Failed to create user', 'error');
      }
    } catch (error) {
      console.error('Error creating user:', error);
      showNotification('Failed to create user', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = (userId) => {
    setUsers(users.filter(user => user._id !== userId));
    showNotification('User deleted successfully', 'success');
  };

  return (
    <div style={styles.pageContainer}>
      <SideMenu />
      <div style={styles.mainContent}>
        <div style={styles.contentContainer}>
          {/* Left side - Create User Form */}
          <div style={styles.formSection}>
            <h1 style={styles.title}>Account Management</h1>
            <div style={styles.formContainer}>
              <h2 style={styles.subtitle}>Create New User</h2>
              <form onSubmit={handleCreateUser} style={styles.form}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  style={styles.input}
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={styles.input}
                  required
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={styles.input}
                  required
                />
                <button type="submit" style={styles.button} disabled={loading}>
                  {loading ? 'Creating...' : 'Create User'}
                </button>
              </form>
            </div>
          </div>

          {/* Right side - User List */}
          <div style={styles.userListSection}>
            <h2 style={styles.subtitle}>User Accounts</h2>
            <div style={styles.userList}>
              {Array.isArray(users) && users.length > 0 ? (
                users.map((user) => (
                  <UserCard 
                    key={user._id} 
                    user={user} 
                    onDelete={handleDeleteUser}
                  />
                ))
              ) : (
                <div style={styles.noUsers}>No users found</div>
              )}
            </div>
          </div>
        </div>

        {/* Custom Alert */}
        {showAlert && (
          <CustomAlert 
            type={alertType}
            message={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    minHeight: '100vh',
  },
  mainContent: {
    flex: 1,
    padding: '20px',
    marginLeft: '180px',
  },
  contentContainer: {
    display: 'flex',
    gap: '20px',
    height: 'calc(100vh - 40px)',
    justifyContent: 'space-between',
  },
  formSection: {
    flex: '1',
    minWidth: '0',
  },
  userListSection: {
    width: '300px',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    overflowY: 'auto',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 80px)',
    marginLeft: 'auto',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#2c3e50',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#34495e',
  },
  formContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    fontSize: '14px',
  },
  button: {
    padding: '12px',
    backgroundColor: '#2c3e50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
    ':hover': {
      backgroundColor: '#34495e',
    },
  },
  userList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  userCard: {
    padding: '15px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    border: '1px solid #eee',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  userHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  username: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#2c3e50',
  },
  passwordContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  password: {
    fontSize: '14px',
    color: '#7f8c8d',
    fontFamily: 'monospace',
  },
  showPasswordButton: {
    padding: '4px 8px',
    fontSize: '12px',
    backgroundColor: '#ecf0f1',
    border: '1px solid #bdc3c7',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#34495e',
    transition: 'all 0.2s ease',
    ':hover': {
      backgroundColor: '#bdc3c7',
    },
  },
  deleteButton: {
    padding: '4px 8px',
    fontSize: '12px',
    backgroundColor: '#e74c3c',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    ':hover': {
      backgroundColor: '#c0392b',
    },
  },
  alert: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    minWidth: '300px',
    padding: '12px 20px',
    borderRadius: '4px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1000,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    padding: '0 5px',
    marginLeft: '10px',
    opacity: 0.7,
    ':hover': {
      opacity: 1,
    },
  },
  noUsers: {
    textAlign: 'center',
    padding: '20px',
    color: '#666',
    fontSize: '16px',
  },
};

export default AccountManagement;
