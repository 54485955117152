import { useContext } from 'react';
import { AdminContext } from '../context/AdminContext';

const useAdmin = () => {
  const context = useContext(AdminContext);

  if (!context) {
    throw new Error('useAdmin must be used inside an AdminContextProvider');
  }

  return context;
};

export default useAdmin;
